import { FC, ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Icon } from '@common/atoms/Icon';
import { ModuleScheduleItem, News, Pagination, PlatformID, Tag } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { DateTimeUtil, Format } from '@common/utils/DateTimeUtil';
import { useTranslation } from '@pxr/i18n';
import { MediaQuery, MediaQueryDirection, useResponsive } from '@web/hooks/useResponsive/useResponsive';
import { Tab } from '@web/molecules/IconTabs';
import { Divider } from '@web/molecules/NewsList/Divider';
import { FeaturedNewsList } from '@web/molecules/NewsList/FeaturedNewsList';
import { NewsListItem } from '@web/molecules/NewsList/NewsListItem';
import { PromoItem } from '@web/molecules/NewsList/PromoItem/PromoItem';
import { ReadMoreButton } from '@web/molecules/NewsList/ReadMoreButton';
import { getMoreNewsButtonText } from '@web/molecules/NewsList/utils';

import { CarrouselWrapper } from './CarrouselWrapper';

import styles from './NewsList.module.scss';

export enum DisplayType {
    compact = 'compact',
    list = 'list',
    blocks = 'blocks',
    carrousel = 'carrousel',
}

export interface Props {
    items: News[] | undefined;
    isAside?: boolean;
    featuredItems?: News[] | undefined;
    promoItems?: ModuleScheduleItem[] | undefined;
    pagination?: Pagination;
    showIconTabs?: boolean;
    tag?: Tag;
    title?: JSX.Element;
    moreArticlesLink?: string;
    moreArticlesLinkText?: string;
    skipDivider?: boolean;
    ComponentTitle?: ReactElement;
    displayType?: DisplayType;
    displayTypeSwitchOptions?: DisplayType[];
    mobileDisplayType?: DisplayType;
    onReadMoreClick?: (pagination: Pagination | undefined) => void;
    isHighlighted?: boolean;
    injections?: Array<JSX.Element | undefined>;
}

export const NewsList: FC<Props> = (props) => {
    const __newsList = useTranslation('newslist').t;
    const { context, platform } = useContextData();

    const initialLoadDisplayType = useRef(
        props.displayType ?? props.displayTypeSwitchOptions?.[0] ?? DisplayType.list,
    );
    const [displayType, setDisplayType] = useState<DisplayType>(initialLoadDisplayType.current);
    const onIconTabClick = useCallback((type: DisplayType): void => {
        initialLoadDisplayType.current = type;
        setDisplayType(type);
    }, []);

    // Handle displayType change by isMobile boolean
    const isMobile: boolean = useResponsive({ direction: MediaQueryDirection.below, size: MediaQuery.l });
    useEffect(() => {
        if (props?.mobileDisplayType) {
            const shouldChangeDisplayType = isMobile && props.mobileDisplayType !== displayType;
            const shouldRestoreDisplayType = !isMobile && displayType !== initialLoadDisplayType.current;

            if (shouldChangeDisplayType) {
                setDisplayType(props.mobileDisplayType);
            } else if (shouldRestoreDisplayType) {
                setDisplayType(initialLoadDisplayType.current);
            }
        }
    }, [props?.mobileDisplayType, isMobile, displayType]);

    const firstElementRef = useRef<HTMLLIElement>(null);

    // Handle click action for slider
    const onReadMoreClick = (pagination: Pagination | undefined): void => {
        if (props.onReadMoreClick) {
            props.onReadMoreClick(pagination);
        }
    };

    const items: Array<JSX.Element> = [];
    let lastDivider: string;

    let iconTabs: Tab[] = [
        {
            type: DisplayType.list,
            icon: <Icon.listBig />,
        },
        {
            type: DisplayType.compact,
            icon: <Icon.listSmall />,
        },
    ];

    //Only show the recieved items in props
    if (Array.isArray(props.displayTypeSwitchOptions)) {
        iconTabs = [];

        //Add the selectedType, if it is not passed to the displayTypes
        if (
            !props.displayTypeSwitchOptions?.find((_displayType) => {
                return displayType === _displayType;
            })
        ) {
            props.displayTypeSwitchOptions?.unshift(displayType);
        }

        props.displayTypeSwitchOptions?.forEach((displayType) => {
            let _icon: ReactElement = <></>;
            switch (displayType) {
                case DisplayType.list:
                    _icon = <Icon.listBig />;
                    break;
                case DisplayType.compact:
                    _icon = <Icon.listSmall />;
                    break;
                case DisplayType.blocks:
                    _icon = <Icon.blocks />;
                    break;
                case DisplayType.carrousel:
                    _icon = <Icon.carrousel />;
                    break;
            }
            iconTabs.push({
                type: displayType,
                icon: _icon,
            });
        });
    }

    const featuredItems = useMemo(
        () =>
            props?.featuredItems && props.featuredItems.length > 0 ? (
                <FeaturedNewsList items={props.featuredItems} />
            ) : null,
        [props?.featuredItems],
    );

    const topItems = useMemo(
        () => props.promoItems?.filter((promo) => !promo?.frequency && promo),
        [props?.promoItems],
    );

    // Add all inserts for newslist - PB-3546
    const fallbackTitle = `${__newsList`the-latest`} ${props.tag?.title || ''} ${__newsList`news`}`;
    const moreArticlesLinkText = props?.moreArticlesLinkText
        ? props.moreArticlesLinkText
        : displayType === DisplayType.carrousel
          ? __newsList`more-videos`
          : __newsList`show-more`;

    props.items?.forEach((item: News, index: number) => {
        let divider: ReactNode = false;
        const dividerDate: string =
            // FIXME: PB-#### skip dividers when on club pages, as they are not needed
            // ...find a better solution for this
            // ...find a better solution for this
            displayType === DisplayType.compact || displayType === DisplayType.list
                ? DateTimeUtil.format(new Date(item.newsDate), Format.DATE_MEDIUM, context.locale)
                : '';

        if (props.skipDivider) {
            // do nothing
        } else if (index === 0) {
            divider = (
                <Divider
                    key={'divider-' + index}
                    className={styles.divider + ' ' + styles.title}
                    title={props?.title ? props.title : <h3>{fallbackTitle}</h3>}
                    hasBorder
                    iconTabs={iconTabs}
                    moreArticlesLink={{
                        text: moreArticlesLinkText,
                        url: props?.moreArticlesLink || '',
                        isVisible: !(
                            context.platform === PlatformID.BR && displayType === DisplayType.carrousel
                        ),
                    }}
                    onIconTabClick={onIconTabClick}
                    showIconTabs={props.showIconTabs}
                    displayType={displayType}
                />
            );
        } else if (dividerDate !== lastDivider) {
            divider = (
                <Divider
                    key={'divider-' + index}
                    className={styles.divider + ' ' + styles.date}
                    title={<h3>{dividerDate}</h3>}
                    hasBorder
                />
            );
        }
        lastDivider = dividerDate;

        // Push injections above divider
        const injectionAtCurrentIndex =
            props?.injections && props?.injections[index] ? props.injections[index] : null;
        const hasVerticalSpacing = displayType === DisplayType.compact || displayType === DisplayType.list;
        if (props?.items && injectionAtCurrentIndex) {
            const injectionMargins = hasVerticalSpacing
                ? index === 0
                    ? styles['injection-margin-bottom']
                    : index === props.items.length - 1
                      ? styles['injection-margin-top']
                      : styles['injection-margin-y']
                : '';
            items.push(
                <li className={`${styles.injection} ${injectionMargins}`} key={`injection-item_${index}`}>
                    {injectionAtCurrentIndex}
                </li>,
            );
        }

        if (featuredItems && index === 0) {
            items.push(featuredItems);
        }

        // Push divider
        if (divider) items.push(divider);

        if (index === 0 && topItems) {
            topItems.forEach((promo: ModuleScheduleItem) => {
                items.push(<PromoItem item={promo} key={`promoItem-${index}`} displayType={displayType} />);
            });
        }

        // Push promoItems
        if (props?.promoItems && index > 0) {
            props.promoItems.forEach((promo: ModuleScheduleItem) => {
                if (promo?.frequency && (index + 1) % promo.frequency === 0) {
                    items.push(
                        <PromoItem item={promo} key={`promoItem-${index}`} displayType={displayType} />,
                    );
                }
            });
        }

        // Push newsItems
        items.push(
            <NewsListItem
                item={item}
                tag={props.tag}
                key={`NewsListItem-${index}`}
                displayType={displayType}
                firstElementRef={index === 0 ? firstElementRef : undefined}
            />,
        );
    });

    if (items.length === 0) {
        // Nothing to show
        if (!topItems) return <></>;

        // Promo to show
        items.push(
            <li className={styles.divider + ' ' + styles.date} key={'divider'}>
                <h3 className="list-heading">
                    {__newsList`the-latest`} {props.tag?.title} {__newsList`news`}
                </h3>
            </li>,
        );
        topItems.forEach((promo: ModuleScheduleItem, index: number) => {
            items.push(<PromoItem item={promo} key={`promoItem-${index}`} displayType={displayType} />);
        });
    }

    // let readMore: ReactElement = <></>;
    // Refactor below to a switch to make it easier to add more platforms
    const platformButtonIcon =
        platform.id === PlatformID.GP || platform.id === PlatformID.VI ? (
            <Icon.down />
        ) : platform.id === PlatformID.BR ? (
            <Icon.right />
        ) : (
            <></>
        );

    const platformButtonText = getMoreNewsButtonText(props.items, platform, __newsList);

    const newsCount = props.items?.length || 0;

    const classes = [styles.NewsList];
    classes.push(styles[`${displayType}-display-type`]);
    props?.isHighlighted && classes.push('is-highlighted inverted');

    const { ComponentTitle } = props;
    const Element = props.isAside ? 'aside' : 'section';
    return (
        <Element className={classes.join(' ')}>
            {ComponentTitle ? ComponentTitle : null}
            {displayType === DisplayType.carrousel ? (
                <>
                    <CarrouselWrapper
                        defaultProps={props}
                        moreArticlesLink={{
                            text: moreArticlesLinkText,
                            url: props?.moreArticlesLink || '',
                        }}
                        isHighlighted={Boolean(props?.isHighlighted)}
                        {...{
                            items,
                            firstElementRef,
                            newsCount,
                        }}
                    />
                </>
            ) : (
                <>
                    <ul>{items}</ul>
                    {props?.pagination?.hasNextPage ? (
                        <ReadMoreButton
                            isHighlighted={props?.isHighlighted}
                            onClick={() => onReadMoreClick(props.pagination)}
                            label={platformButtonText}
                            icon={platformButtonIcon}
                        />
                    ) : null}
                </>
            )}
        </Element>
    );
};
